import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { loginWithGoogle, checkAuthStatus } from "../utils/auth";

export default function Login() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    // 🔹 Check if user is already logged in
    useEffect(() => {
        checkAuthStatus().then((user) => {
            if (user) router.push("/home"); // Redirect if already authenticated
        });
    }, []);

    // 🔹 Handle Google Login
    const handleGoogleLogin = async () => {
        setLoading(true);
        setError(null);
        try {
            const { user, isNewUser } = await loginWithGoogle();
            if (user) {
                router.push(isNewUser ? "/complete-profile" : "/home"); // Redirect accordingly
            } else {
                setError("Google login failed. Try again.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error("Google Login Error:", error);
        }
        setLoading(false);
    };


    return (
        <div className="container"> 
             {/* Logo */}
             <div className="logo-container">
                <img src="/logo-enem-app-color.png" className="logo" alt="Enem App Logo" />
            </div>
            <div className="login-container">
                
                <h2>Login to enem.app</h2>

                <button onClick={handleGoogleLogin} className="login-button google-login" disabled={loading}>
                    {loading ? "Logging in..." : "Login with Google"}
                </button>


                {error && <p className="login-error">{error}</p>}
            </div>
        </div>
    );
}

import { 
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider, 
    signInWithEmailAndPassword, 
    signOut, 
    onAuthStateChanged 
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "./firebase"; // Ensure Firebase is correctly imported

const googleProvider = new GoogleAuthProvider();

/**
 * 🔹 Google Login Function
 * - Opens Google sign-in popup
 * - Upserts the user record in Firestore (stores email, etc.)
 * - Returns isNewUser = true if user doc doesn't exist yet
 */
export const loginWithGoogle = async () => {
    try {
        const authInstance = getAuth();
        const result = await signInWithPopup(authInstance, googleProvider);
        const user = result.user;

        // Store user ID in localStorage (if needed for client logic)
        localStorage.setItem("userId", user.uid);

        // Reference to Firestore user document
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        // Determine if this is a new user
        const isNewUser = !userSnap.exists();

        // Upsert user data in Firestore
        await setDoc(
            userRef,
            {
                uid: user.uid,
                email: user.email,
                displayName: user.displayName || "",
                photoURL: user.photoURL || "",
                lastLogin: new Date().toISOString()
                // You can store any other fields from user or default values here
            },
            { merge: true } // Merge so we don't overwrite existing fields
        );

        return { user, isNewUser };
    } catch (error) {
        console.error("Google login error:", error);
        return { user: null, isNewUser: false };
    }
};

/**
 * 🔹 Save User Details (First Name, Last Name, School)
 * - Called after login if the user is new
 */
export const saveUserDetails = async (userId, firstName, lastName, school) => {
    try {
        const userRef = doc(db, "users", userId);
        await setDoc(userRef, {
            uid: userId,
            firstName,
            lastName,
            school,
            status: "free",
            createdAt: new Date().toISOString(),
            expirationDate: null, // Future paid users will have an expiration date
        }, { merge: true }); // `merge: true` ensures we don't overwrite existing data

        return true;
    } catch (error) {
        console.error("Error saving user details:", error);
        return false;
    }
};

/**
 * 🔹 Email & Password Login Function
 * - Logs user in using Firebase Authentication
 */
export const loginWithEmail = async (email, password) => {
    try {
        const authInstance = getAuth();
        const userCredential = await signInWithEmailAndPassword(authInstance, email, password);
        const user = userCredential.user;

        // Store user ID in localStorage
        localStorage.setItem("userId", user.uid);

        return user;
    } catch (error) {
        console.error("Email login error:", error);
        return null;
    }
};

/**
 * 🔹 Logout Function
 * - Signs the user out from Firebase Authentication
 */
export const logout = async () => {
    try {
        const authInstance = getAuth();
        await signOut(authInstance);
    } catch (error) {
        console.error("Logout error:", error);
    }
};

/**
 * 🔹 Check Authentication Status
 * - Returns the current user if logged in
 */
export const checkAuthStatus = () => {
    return new Promise((resolve) => {
        const authInstance = getAuth();
        onAuthStateChanged(authInstance, (user) => {
            if (user) {
                localStorage.setItem("userId", user.uid); 
            }
            resolve(user);
        });
    });
};

import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection } from "firebase/firestore";

// 🔹 Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyAoeqTuBIe-SKM5ou1CtnyHc4w-HCF-edE",
  authDomain: "enemapp-451914.firebaseapp.com",
  projectId: "enemapp-451914",
  storageBucket: "enemapp-451914.firebasestorage.app",
  messagingSenderId: "749847122280",
  appId: "1:749847122280:web:861612a5f1fec6bb6506dd",
  measurementId: "G-2YYPBVN1SK"
};

// ✅ Ensure Firebase is only initialized once
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const db = getFirestore(app);

let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

// 🔹 Debugging Logs
console.log("✅ Firebase Initialized Successfully:", app);
console.log("✅ Auth Initialized Successfully:", auth);
console.log("✅ Firestore Initialized Successfully:", db);

export { app, analytics, db, auth };
